import { API_BASE_URL } from 'configs/AppConfig';
import ls from 'localstorage-slim';

const baseService = {};

baseService.getPost = async function (url, query) {
	let token = ls.get('auth_token', { decrypt: true });
	let data = [];
	let getUrl = API_BASE_URL + url + '?token=' +token;

	if (query) {
		getUrl = API_BASE_URL + url + '?whisper=' + query +'&token=' + token;
	}

	async function signOutFunc() {
		window.location.reload(false);
	}
	await fetch(getUrl)
		.then((res) =>
			res?.status === 200
				? res.json()
				: res?.status === 401
				? signOutFunc()
				: []
		)
		.then((result) => {
			data = result;
		})
		.catch((err) => {
			console.log('BaseServiceErrorGET', err)
		});
	return data;
};

baseService.getQueryPost = async function (url, page, perPage, typeRequest) {
	let token = ls.get('auth_token', { decrypt: true });
	let data = [];
	let getUrl = API_BASE_URL + url + '?token=' +token;

	if (page || perPage || typeRequest) {
		getUrl = API_BASE_URL + url + '?page=' + page  +'&perPage=' + perPage  +'&typeRequest=' + typeRequest +'&token=' + token;
	}

	async function signOutFunc() {
		window.location.reload(false);
	}

	await fetch(getUrl)
		.then((res) =>
			res?.status === 200
				? res.json()
				: res?.status === 401
					? signOutFunc()
					: []
		)
		.then((result) => {
			data = result;
		})
		.catch((err) => {
			console.log('BaseServiceErrorGET', err)
		});
	return data;
};

baseService.setPostQuery = async function (url, data, page, perPage, typeRequest) {
	let token = ls.get('auth_token', { decrypt: true });

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	};

	let entity = {};

	let getUrl = API_BASE_URL + url + '?token=' +token;

	if (page || perPage || typeRequest) {
		getUrl = API_BASE_URL + url + '?page=' + page  +'&perPage=' + perPage  +'&typeRequest=' + typeRequest +'&token=' + token;
	}

	await fetch(getUrl, requestOptions)
		.then((res) => (res?.status === 200 ? res.json() : []))
		.then((result) => {
			entity = result;
		})
		.catch((err) => {
			console.log('BaseServiceErrorPOST', err)
		});

	return entity;
};

baseService.setPost = async function (url, data) {
	let token = ls.get('auth_token', { decrypt: true });

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	};

	let entity = {};
	await fetch(API_BASE_URL + url + '?token=' + token, requestOptions)
		.then((res) => (res?.status === 200 ? res.json() : []))
		.then((result) => {
			entity = result;
		})
		.catch((err) => {
			console.log('BaseServiceErrorPOST', err)
		});

	return entity;
};

baseService.createLog = async function (url, userAction, localUserId) {
	let userId = ls.get("id", { decrypt: true });

	if (localUserId) {
		userId = localUserId;
	}

	let token = ls.get('auth_token', { decrypt: true });

	let data = [];

	let getUrl = API_BASE_URL + url + '?token=' +token + '&userId=' + userId;

	if (userAction) {
		getUrl = API_BASE_URL + url + '?token=' + token + '&userId=' + userId + '&action=' + userAction;
	}

	async function signOut() {
		window.location.reload(false);
	}

	await fetch(getUrl)
		.then((res) =>
			res?.status === 200
				? res.json()
				: res?.status === 401
					? signOut()
					: []
		)
		.then((result) => {
			data = result;
		})
		.catch((err) => {
			console.log('BaseServiceErrorGET', err)
		});
	return data;
};

export default baseService;
