import React, { lazy } from "react";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import ls from 'localstorage-slim';

// Auth Views Route

const Login = lazy(() => import(`./auth-views/authentication/login`));
const Login1 = lazy(() => import(`./auth-views/authentication/login-1`));
const ForgotPassword = lazy(() => import(`./auth-views/authentication/forgot-password`));
const ErrorPage1 = lazy(() => import(`./auth-views/errors/error-page-1`));
const ErrorPage2 = lazy(() => import(`./auth-views/errors/error-page-2`));

// Auth Views Route


// App Views Route

// --> Dashboard Route
const Dashboard = lazy(() => import(`./app-views/dashboard`));

const DashboardViewAgent = lazy(() => import(`./app-views/dashboard/agent`));
const DashboardViewSuperVisor = lazy(() => import(`./app-views/dashboard/supervisor`));
const DashboardViewSpy = lazy(() => import(`./app-views/dashboard/spy`));

// --> Agent Route
const Agent = lazy(() => import(`./app-views/agent`));

const AgentViewAgentAdd = lazy(() => import(`./app-views/agent/add/index`));
const AgentViewAgentEdit = lazy(() => import(`./app-views/agent/edit/index`));
const AgentViewAgentList = lazy(() => import(`./app-views/agent/list/index`));

// --> Admin Route
const Admin = lazy(() => import(`./app-views/admin`));

const AdminViewAdminAdd = lazy(() => import(`./app-views/admin/add/index`));
const AdminViewAdminEdit = lazy(() => import(`./app-views/admin/edit/index`));
const AdminViewAdminList = lazy(() => import(`./app-views/admin/list/index`));

// --> SuperVisor Route
const SuperVisor = lazy(() => import(`./app-views/supervisor`));

const SuperVisorViewSuperVisorAdd = lazy(() => import(`./app-views/supervisor/add/index`));
const SuperVisorViewSuperVisorEdit = lazy(() => import(`./app-views/supervisor/edit/index`));
const SuperVisorViewSuperVisorList = lazy(() => import(`./app-views/supervisor/list/index`));

// --> Break Route
const Break = lazy(() => import(`./app-views/mola`));

const BreakViewBreakAdd = lazy(() => import(`./app-views/mola/add/index`));
const BreakViewBreakEdit = lazy(() => import(`./app-views/mola/edit/index`));
const BreakViewBreakList = lazy(() => import(`./app-views/mola/list/index`));

// --> Queue Route
const Queue = lazy(() => import(`./app-views/queue`));

const QueueViewQueueList = lazy(() => import(`./app-views/queue/list/index`));

// --> Call Route
const Call = lazy(() => import(`./app-views/call`));

const CallViewPhone = lazy(() => import(`./app-views/call/phone/index`));
const CallViewPhoneV2 = lazy(() => import(`./app-views/call/phone2/index`));

// --> Reports Route
const Reports = lazy(() => import(`./app-views/reports`));

const ReportsViewGelenAramaRaporu = lazy(() => import(`./app-views/reports/gelenaramaraporu/index`));
const ReportsViewMola = lazy(() => import(`./app-views/reports/mola/index`));
const ReportsViewMolaOzet = lazy(() => import(`./app-views/reports/molaozet/index`));
const ReportsViewLoginLogoutRaporu = lazy(() => import(`./app-views/reports/loginlogoutraporu/index`));
const ReportsViewLoginLogoutRaporuOzet = lazy(() => import(`./app-views/reports/loginlogoutraporuozet/index`));
const ReportsViewAgentAramaRaporu = lazy(() => import(`./app-views/reports/agentaramaraporu/index`));
const ReportsViewKuyruktAramaRaporu = lazy(() => import(`./app-views/reports/kuyruktaramaraporu/index`));
const ReportsViewKacanAramaRaporu = lazy(() => import(`./app-views/reports/kacanaramaraporu/index`));
const ReportsViewKuyruktAramaRaporuOzet = lazy(() => import(`./app-views/reports/kuyruktaramaraporuozet/index`));
const ReportsViewAgentAramaRaporuOzet = lazy(() => import(`./app-views/reports/agentaramaraporuozet/index`));
const ReportsViewGelenAramaRaporuOzet = lazy(() => import(`./app-views/reports/gelenaramaraporuozet/index`));
const ReportsViewAgentCallHistory = lazy(() => import(`./app-views/reports/agent/call-history/index`));
const ReportsViewAgentBreakHistory = lazy(() => import(`./app-views/reports/agent/break-history/index`));

// --> Customer Route
const Customer = lazy(() => import(`./app-views/musteri`));

const CustomerViewSuperVisorList = lazy(() => import(`./app-views/musteri/list/index`));
const CustomerViewSuperVisorEdit = lazy(() => import(`./app-views/musteri/edit/index`));
const CustomerViewSuperVisorNoteList = lazy(() => import(`./app-views/musteri/notelist/index`));
const CustomerViewSuperVisorAdd =  lazy(() => import(`./app-views/musteri/add/index`));

// --> BlackList Route
const BlackList = lazy(() => import(`./app-views/blackList`));

const BlackListViewBlackList = lazy(() => import(`./app-views/blackList/list/index`));

// --> BlackListOutbound Route
const BlackListOutbound = lazy(() => import(`./app-views/blackListOutbound`));

const BlackListOutBoundViewBlackListOutBound = lazy(() => import(`./app-views/blackListOutbound/list/index`));

// --> QueueEdit Route
const QueueEdit = lazy(() => import(`./app-views/kuyrukduzenle`));

const QueueListViewQueueList = lazy(() => import(`./app-views/kuyrukduzenle/list/index`));

// --> CRM Route
const CRM = lazy(() => import(`./app-views/crm`));

// --> Dashboard Spy Route
const DashboardSpy = lazy(() => import(`./app-views/dashboard/spy`));

// --> Group Route
const Group = lazy(() => import(`./app-views/group`));

const GroupViewGroupAdd = lazy(() => import(`./app-views/group/add/index`));
const GroupViewGroupList = lazy(() => import(`./app-views/group/list/index`));

// --> Logs Route
const LOGS = lazy(() => import(`./app-views/logs`));

// App Views Route

export const Views = (props) => {
    const { locale, token } = props;

    const currentAppLocale = AppLocale[locale];

    const location = useLocation();

    let role = ls.get("role", { decrypt: true });

    let yetki = ls.get("yetki", { decrypt: true });

    yetki === 1 ? yetki = true : yetki = false;

    return (
        <IntlProvider
            locale={currentAppLocale.locale ? currentAppLocale.locale : 'tr'}
            defaultLocale={currentAppLocale.locale ? currentAppLocale.locale : 'tr'}
            onError={() => { }}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd}>

                <Routes>

                    <Route path="/" element={ <Navigate to={APP_PREFIX_PATH} /> } />

                    <Route path={`${AUTH_PREFIX_PATH}/*`} element={ <AuthLayout /> }>

                        <Route index={true} element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        <Route path={`login`} element={ <Login /> } />
                        <Route path={`login-1`} element={ <Login1 /> } />
                        <Route path={`forgot-password`} element={ <ForgotPassword /> } />
                        <Route path={`error-1`} element={ <ErrorPage1 /> } />
                        <Route path={`error-2`} element={ <ErrorPage2 /> } />

                        <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                    </Route>

                    <Route path={`${APP_PREFIX_PATH}/*`} element={
                        token ?  <AppLayout location={location} /> : <Navigate
                            to={{
                                pathname: AUTH_PREFIX_PATH,
                                state: { from: location }
                            }}
                        />
                    }>

                        <Route index={true} element={<Navigate to={`${APP_PREFIX_PATH}/dashboard`} />} />

                        <Route path={`dashboard/*`} element={ <Dashboard />}>

                            <Route index={true} element={ role === "AGENT" ? ( <Navigate to={`${APP_PREFIX_PATH}/dashboard/agent`} />) :
                                ( <Navigate to={`${APP_PREFIX_PATH}/dashboard/agent`} /> )
                            } />

                            <Route path={`agent`} element={ <DashboardViewAgent /> } />
                            <Route path={`supervisor`} element={ <DashboardViewSuperVisor /> } />
                            <Route path={`spy`} element={ <DashboardViewSpy /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`agent/*`} element={ <Agent /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/agent/list`} /> } />

                            <Route path={`add`} element={ <AgentViewAgentAdd /> } />
                            <Route path={`edit/:id`} element={ <AgentViewAgentEdit /> } />
                            <Route path={`list`} element={ <AgentViewAgentList /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`admin/*`} element={ <Admin /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/admin/add`} /> } />

                            <Route path={`add`} element={ <AdminViewAdminAdd /> } />
                            <Route path={`edit/:id`} element={ <AdminViewAdminEdit /> } />
                            <Route path={`list`} element={ <AdminViewAdminList /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`supervisor/*`} element={ <SuperVisor /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/supervisor/add`} /> } />

                            <Route path={`add`} element={ <SuperVisorViewSuperVisorAdd /> } />
                            <Route path={`edit/:id`} element={ <SuperVisorViewSuperVisorEdit /> } />
                            <Route path={`list`} element={ <SuperVisorViewSuperVisorList /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`mola/*`} element={ <Break /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/mola/list`} /> } />

                            <Route path={`add`} element={ <BreakViewBreakAdd /> } />
                            <Route path={`edit/:id`} element={ <BreakViewBreakEdit /> } />
                            <Route path={`list`} element={ <BreakViewBreakList /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`queue/*`} element={ <Queue />}>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/queue/list`} /> } />

                            <Route path={`list`} element={
                                role === "AGENT" && yetki? <QueueViewQueueList /> : null
                            } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`call/*`} element={ <Call /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/call/phone`} /> } />

                            <Route path={`phone`} element={ <CallViewPhone /> } />
                            <Route path={`phone2`} element={ <CallViewPhoneV2 /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`reports/*`} element={ <Reports /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/reports/gelenaramaraporu`} /> } />

                            <Route path={`gelenaramaraporu`} element={ <ReportsViewGelenAramaRaporu /> } />

                            <Route path={`mola`} element={ <ReportsViewMola /> } />

                            <Route path={`molaozet`} element={ <ReportsViewMolaOzet /> } />

                            <Route path={`loginlogoutraporu`} element={ <ReportsViewLoginLogoutRaporu /> } />

                            <Route path={`loginlogoutraporuozet`} element={ <ReportsViewLoginLogoutRaporuOzet /> } />

                            <Route path={`agentaramaraporu`} element={ <ReportsViewAgentAramaRaporu /> } />

                            <Route path={`kuyruktaramaraporu`} element={ <ReportsViewKuyruktAramaRaporu /> } />

                            <Route path={`kacanaramaraporu`} element={ <ReportsViewKacanAramaRaporu /> } />

                            <Route path={`kuyruktaramaraporuozet`} element={ <ReportsViewKuyruktAramaRaporuOzet /> } />

                            <Route path={`agentaramaraporuozet`} element={ <ReportsViewAgentAramaRaporuOzet /> } />

                            <Route path={`gelenaramaraporuozet`} element={ <ReportsViewGelenAramaRaporuOzet /> } />

                            <Route path={`agent/call-history/`} element={ <ReportsViewAgentCallHistory /> } />

                            <Route path={`agent/break-history/`} element={ <ReportsViewAgentBreakHistory /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`musteri/*`} element={ <Customer /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/musteri/list`} /> } />

                            <Route path={`list`} element={ <CustomerViewSuperVisorList /> } />
                            <Route path={`edit/:id`} element={ <CustomerViewSuperVisorEdit /> } />
                            <Route path={`notelist`} element={ <CustomerViewSuperVisorNoteList /> } />
                            <Route path={`add`} element={ <CustomerViewSuperVisorAdd /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`blackList/*`} element={ <BlackList /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/blackList/list`} /> } />

                            <Route path={`list`} element={<BlackListViewBlackList />} />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`blackListOutbound/*`} element={ <BlackListOutbound /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/blackListOutbound/list`} /> } />

                            <Route path={`list`} element={ <BlackListOutBoundViewBlackListOutBound /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`kuyrukduzenle/*`} element={ <QueueEdit /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/kuyrukduzenle/list`} /> } />

                            <Route path={`list`} element={ <QueueListViewQueueList /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`crm/*`} element={ <CRM /> }>

                            <Route index={true}  element={ <CRM /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`dashboard/spy/*`}>

                            <Route index={true}  element={ <DashboardSpy /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`group/*`} element={ <Group /> }>

                            <Route index={true}  element={ <Navigate to={`${APP_PREFIX_PATH}/group/list`} /> } />

                            <Route path={`add`} element={ <GroupViewGroupAdd /> } />

                            <Route path={`list`} element={ <GroupViewGroupList /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path={`logs/*`} element={ <LOGS /> }>

                            <Route index={true}  element={ <LOGS /> } />

                            <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                        </Route>

                        <Route path="*" element={<Navigate to={`${AUTH_PREFIX_PATH}/login`} />} />

                    </Route>
                </Routes>
            </ConfigProvider>
        </IntlProvider>
    )
}


const mapStateToProps = ({ theme, auth }) => {
    const { locale } =  theme;
    const { token } = auth;
    return { locale, token }
};

export default (connect(mapStateToProps)(Views));