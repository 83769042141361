import React, {useCallback, useEffect, useState} from 'react';
import { Route, Routes} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Views from './views';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { KategoriProvider } from './contexts/KategoriContext'
import { CounterProvider } from 'contexts/CounterContext';
import baseService from 'services/BaseService';
import { QueueRefresherProvider } from 'contexts/QueueRefresherContext';
import { Modal } from 'antd';
import ls from 'localstorage-slim';

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
    const { confirm } = Modal;
    const [isAppVisible, setIsAppVisible] = useState(false)

    const showConfirm = useCallback( () => {
        confirm({
            title: 'Uyarı',
            content: 'Bu sayfa başka bir sekmede aktif olarak görünüyor. Aktif sekmeleri kapatmak istiyor musunuz?',
            onOk() {
                ls.remove("auth_token");
                window.location.reload(false);
            },
            okText: 'Evet',
            cancelText: 'Hayır',
        });
    }, [confirm]);

    useEffect(() => {
        let token = ls.get("auth_token", { decrypt: true });
        const sipScript = document.createElement('script');
        const setcallScript = document.createElement('script');
        const momentScript = document.createElement('script');
        const localStorageScript = document.createElement('script');

        if (token) {
            showConfirm()
        } else {
            sipScript.src = `${process.env.PUBLIC_URL}/js/sip-0.20.0.min.js`;
            sipScript.async = true;
            document.body.appendChild(sipScript);

            setcallScript.src = `${process.env.PUBLIC_URL}/js/setcallMain.js`;
            setcallScript.async = true;
            document.body.appendChild(setcallScript);

            momentScript.src = `${process.env.PUBLIC_URL}/js/moment-with-locales-2.24.0.min.js`;
            momentScript.async = true;
            document.body.appendChild(momentScript);

            localStorageScript.src = `${process.env.PUBLIC_URL}/js/localstorage-slim.js`;
            localStorageScript.async = true;
            document.body.appendChild(localStorageScript);

            setIsAppVisible(true)
        }

        return () => {
            document.body.removeChild(sipScript);
            document.body.removeChild(setcallScript);
            document.body.removeChild(momentScript);
            document.body.removeChild(localStorageScript);
        }
    }, [showConfirm])

    window.addEventListener("beforeunload", async function() {
        if (!window.location.href.includes("auth/login")) {
            const action = 'Sayfa Yenilendi.';

            await baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });

            await logOut();
        }
    });

    window.onbeforeunload = function () {
        if (!window.location.href.includes("auth/login")) {
            logOut();
        }
    };

    const logOut = async () => {
        if (window?.endSession) {
            await window.endSession(1);
        }

        if (window?.Unregister) {
            await window.Unregister();
        }

        let token = ls.get("auth_token", { decrypt: true });

        if (token) {
            let id = ls.get("id", { decrypt: true });
            baseService.setPost("api/userbreak/finish/" + id)
            baseService.setPost("logout/" + id + "/" + false);
            ls.remove("auth_token");
            var start = new Date().getTime();
            while (new Date().getTime() < start + 1000);
        }
        let statusControlWidget = ls.get("statusControlWidget", { decrypt: true });
        if (statusControlWidget) {
            ls.remove("statusControlWidget");
        }
    }

    return (
        <div className={isAppVisible ? "App" : "centered-logo"} >
            <QueueRefresherProvider>
                <KategoriProvider>
                    <CounterProvider>
                        <Provider store={store}>
                            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                                <Routes>
                                    <Route path="/*" element={isAppVisible ? <Views /> : <img src={`${process.env.PUBLIC_URL}/img/setcall-logo-splash.png`} alt="Setcall Logo" />} />
                                    <Route path="*" element={<img src={`${process.env.PUBLIC_URL}/img/setcall-logo-splash.png`} alt="Setcall Logo" />} />
                                </Routes>
                            </ThemeSwitcherProvider>
                        </Provider>
                    </CounterProvider>
                </KategoriProvider>
            </QueueRefresherProvider>
        </div>
    );
}

export default App;