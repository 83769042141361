import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import { env } from "./configs/EnvironmentConfig";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter basename={env.APP_BASENAME}>
        <App />
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
