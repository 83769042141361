import {
	DashboardOutlined,
	CoffeeOutlined,
	CloseOutlined,
	PhoneOutlined,
	NodeExpandOutlined,
	UserOutlined,
	UserAddOutlined,
	PlusOutlined,
	ArrowRightOutlined,
	ArrowLeftOutlined,
	FileTextOutlined,
	SettingOutlined,
	UnorderedListOutlined,
	PieChartOutlined,
	ReloadOutlined,
	SolutionOutlined,
	StopOutlined,
	LoginOutlined,
	GlobalOutlined,
	EyeFilled,
	ApartmentOutlined,
	HistoryOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const agentNavTree = [
	{
		key: 'user',
		path: `${APP_PREFIX_PATH}/user`,
		title: ' ',
		icon: DashboardOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: '2cagrigecmisi',
				path: `${APP_PREFIX_PATH}/reports/agent/call-history`,
				title: 'sidenav.rapor.cagrigecmisi',
				defaultTitle: 'Çağrı geçmişi',
				breadcrumb: false,
				icon: PhoneOutlined,
				submenu: [],
			},
			{
				key: '3molagecmisi',
				path: `${APP_PREFIX_PATH}/reports/agent/break-history`,
				title: 'sidenav.rapor.mola',
				defaultTitle: 'Mola',
				breadcrumb: false,
				icon: CoffeeOutlined,
				submenu: [],
			},
			{
				key: '4dashboard',
				path: `${APP_PREFIX_PATH}/dashboard/agent`,
				title: 'DASHBOARD AGENT',
				defaultTitle: 'Dashboard Agent',
				breadcrumb: false,
				icon: DashboardOutlined,
				submenu: [],
			},
			{
				key: '50dashboard2',
				path: `${APP_PREFIX_PATH}/dashboard/supervisor`,
				title: 'sidenav.dashboard2',
				defaultTitle: 'Dashboard',
				breadcrumb: false,
				icon: PieChartOutlined,
				submenu: [
					{
						key: '51dashboard2-supervisor',
						path: `${APP_PREFIX_PATH}/dashboard/supervisor`,
						title: 'sidenav.dashboard2Supervisor',
						defaultTitle: 'Dashboard spy',
						breadcrumb: false,
						icon: DashboardOutlined,
						submenu: [],
					},
					{
						key: '6spy',
						path: `${APP_PREFIX_PATH}/dashboard/spy`,
						title: 'sidenav.spy',
						defaultTitle: 'DASHBOARD SPY',
						breadcrumb: false,
						icon: EyeFilled,
						submenu: [],
					},
				],
			},
			{
				key: 'user',
				path: `${APP_PREFIX_PATH}/agent/list`,
				title: 'sidenav.user',
				defaultTitle: 'Agent List Kullanıcı',
				breadcrumb: false,
				icon: UserOutlined,
				submenu: [
					{
						key: 'user-ekle',
						path: `${APP_PREFIX_PATH}/agent/add`,
						title: 'sidenav.user.ekle',
						defaultTitle: 'Agent List Kullanıcı Ekle',
						breadcrumb: false,
						icon: PlusOutlined,

						submenu: [],
					},

					{
						key: 'user-list',
						path: `${APP_PREFIX_PATH}/agent/list`,
						title: 'sidenav.user.listele',
						defaultTitle: 'Agent List Kullanıcı Listele',
						breadcrumb: false,
						icon: UnorderedListOutlined,
						submenu: [],
					},
				],
			},
			{
				key: 'mola',
				path: `${APP_PREFIX_PATH}/mola/list`,
				title: 'sidenav.mola',
				defaultTitle: 'Mola',
				breadcrumb: false,
				icon: CoffeeOutlined,
				submenu: [
					{
						key: 'mola-ekle',
						path: `${APP_PREFIX_PATH}/mola/add`,
						title: 'sidenav.mola.ekle',
						defaultTitle: 'Mola Ekle',
						breadcrumb: false,
						icon: PlusOutlined,
						submenu: [],
					},
					{
						key: 'mola-list',
						path: `${APP_PREFIX_PATH}/mola/list`,
						title: 'sidenav.mola.listele',
						defaultTitle: 'Mola Listele',
						breadcrumb: false,
						icon: UnorderedListOutlined,
						submenu: [],
					},
				],
			},

			{
				key: '7rapor-supervisor',
				path: `${APP_PREFIX_PATH}/reports`,
				title: 'sidenav.reports',
				defaultTitle: 'Rapor Supervisor',
				breadcrumb: false,
				icon: FileTextOutlined,
				submenu: [
					{
						key: '8aramaraporu',
						path: `${APP_PREFIX_PATH}/reports/gelenaramaraporu`,
						title: 'sidenav.reports.gelenaramaraporu',
						defaultTitle: 'Gelen Arama Raporu',
						breadcrumb: false,
						icon: PhoneOutlined,
						submenu: [
							{
								key: 'gelenaramagenelraporu',
								path: `${APP_PREFIX_PATH}/reports/gelenaramaraporu`,
								title: 'sidenav.reports.agentaramaraporugenel',
								defaultTitle: 'Agent Arama Raporu Genel',
								breadcrumb: false,
								submenu: [],
							},
							{
								key: 'gelenaramaozetraporu',
								path: `${APP_PREFIX_PATH}/reports/gelenaramaraporuozet`,
								title: 'sidenav.reports.agentaramaraporuozet',
								defaultTitle: 'Agent Arama Raporu Özet',
								breadcrumb: false,
								submenu: [],
							},
						],
					},
					{
						key: '90agentaramaraporu',
						path: `${APP_PREFIX_PATH}/reports/agentaramaraporu`,
						title: 'sidenav.reports.agentaramaraporu',
						defaultTitle: 'Agent Arama Raporu',
						breadcrumb: false,
						icon: UserAddOutlined,
						submenu: [
							{
								key: 'agentaramagenelraporu',
								path: `${APP_PREFIX_PATH}/reports/agentaramaraporu`,
								title: 'sidenav.reports.agentaramaraporugenel',
								defaultTitle: 'Agent Arama Raporu Genel',
								breadcrumb: false,
								submenu: [],
							},
							{
								key: 'agentaramaozetraporu',
								path: `${APP_PREFIX_PATH}/reports/agentaramaraporuozet`,
								title: 'sidenav.reports.agentaramaraporuozet',
								defaultTitle: 'Agent Arama Raporu Özet',
								breadcrumb: false,
								submenu: [],
							},
						],
					},
					{
						key: '91kuyruktaramaraporu',
						path: `${APP_PREFIX_PATH}/reports/kuyruktaramaraporu`,
						title: 'sidenav.reports.kuyruktaramaraporu',
						defaultTitle: 'Kuyrukta Arama Raporu',
						breadcrumb: false,
						icon: NodeExpandOutlined,
						submenu: [
							{
								key: 'kuyruktaramagenelraporu',
								path: `${APP_PREFIX_PATH}/reports/kuyruktaramaraporu`,
								title: 'sidenav.reports.kuyruktaramaraporugenel',
								defaultTitle: 'Kuyrukta Arama Raporu Genel',
								breadcrumb: false,
								submenu: [],
							},
							{
								key: 'kuyruktaramaozetraporu',
								path: `${APP_PREFIX_PATH}/reports/kuyruktaramaraporuozet`,
								title: 'sidenav.reports.kuyruktaramaraporuozet',
								defaultTitle: 'Kuyrukta Arama Raporu Özet',
								breadcrumb: false,
								submenu: [],
							},
						],
					},
					{
						key: '93cevapsizaramaraporu',
						path: `${APP_PREFIX_PATH}/reports/kacanaramaraporu`,
						title: 'sidenav.reports.kacanaramaraporu',
						defaultTitle: 'Kaçan Arama Raporu',
						breadcrumb: false,
						icon: CloseOutlined,
						submenu: [],
					},
					{
						key: '94molaraporu',
						path: `${APP_PREFIX_PATH}/reports/mola`,
						title: 'sidenav.reports.mola',
						defaultTitle: 'Mola Raporu',
						breadcrumb: false,
						icon: CoffeeOutlined,
						submenu: [
							{
								key: 'molagenelraporu',
								path: `${APP_PREFIX_PATH}/reports/mola`,
								title: 'sidenav.reports.molagenel',
								defaultTitle: 'Mola Raporu Genel',
								breadcrumb: false,
								submenu: [],
							},
							{
								key: 'molaozetraporu',
								path: `${APP_PREFIX_PATH}/reports/molaozet`,
								title: 'sidenav.reports.molaozet',
								defaultTitle: 'Mola Raporu Özet',
								breadcrumb: false,
								submenu: [],
							},
						],
					},
					{
						key: '95loginlogoutraporu',
						path: `${APP_PREFIX_PATH}/reports/loginlogoutraporu`,
						title: 'sidenav.reports.loginlogoutraporu',
						defaultTitle: 'Giriş Çıkış Raporu',
						breadcrumb: false,
						icon: LoginOutlined,
						submenu: [
							{
								key: 'loginlogoutgenelraporu',
								path: `${APP_PREFIX_PATH}/reports/loginlogoutraporu`,
								title: 'sidenav.reports.loginlogoutraporugenel',
								defaultTitle: 'Giriş Çıkış Raporu Genel',
								breadcrumb: false,
								submenu: [],
							},
							{
								key: 'loginlogoutozetraporu',
								path: `${APP_PREFIX_PATH}/reports/loginlogoutraporuozet`,
								title: 'sidenav.reports.loginlogoutraporuozet',
								defaultTitle: 'Giriş Çıkış Raporu Özet',
								breadcrumb: false,
								submenu: [],
							},
						],
					},
				],
			},
			{
				key: '96musteri-listesi',
				path: `${APP_PREFIX_PATH}/musteri`,
				title: 'sidenav.musterilistesi',
				defaultTitle: 'Müşteri Listesi',
				breadcrumb: false,
				icon: SolutionOutlined,
				submenu: [
					{
						key: 'musteri-list',
						path: `${APP_PREFIX_PATH}/musteri/list`,
						title: 'sidenav.musterilistesi.liste',
						defaultTitle: 'Müşteri Listele',
						breadcrumb: false,
						icon: UnorderedListOutlined,
						submenu: [],
					},
					{
						key: 'musteri-gecmisi',
						path: `${APP_PREFIX_PATH}/musteri/notelist`,
						title: 'sidenav.musteri.gecmisi',
						defaultTitle: 'Müşteri Geçmişi',
						breadcrumb: false,
						icon: ReloadOutlined,
						submenu: [],
					},
				],
			},
			{
				key: '97crm',
				path: `${APP_PREFIX_PATH}/crm`,
				title: 'sidenav.crm',
				defaultTitle: 'CRM',
				breadcrumb: false,
				icon: GlobalOutlined,
				submenu: [],
			},
			{
				key: '98karaliste',
				path: `${APP_PREFIX_PATH}/blackList`,
				title: 'sidenav.blackList',
				defaultTitle: 'Kara Liste',
				breadcrumb: false,
				icon: StopOutlined,
				submenu: [
					{
						key: 'gelenarama',
						path: `${APP_PREFIX_PATH}/blackList/list`,
						title: 'sidenav.blackList.gelenarama',
						defaultTitle: 'Kara Liste Gelen Arama',
						breadcrumb: false,
						icon: ArrowLeftOutlined,
						submenu: [],
					},
					{
						key: 'gidenarama',
						path: `${APP_PREFIX_PATH}/blackListOutbound/list`,
						title: 'sidenav.blackList.gidenarama',
						defaultTitle: 'Kara Liste Giden Arama',
						breadcrumb: false,
						icon: ArrowRightOutlined,
						submenu: [],
					},
				],
			},
			{
				key: '99sistem',
				path: `${APP_PREFIX_PATH}/sistem`,
				title: 'sidenav.sistem',
				defaultTitle: 'Sistem',
				breadcrumb: false,
				icon: SettingOutlined,
				submenu: [
					{
						key: 'user-list',
						path: `${APP_PREFIX_PATH}/agent/list`,
						title: 'sidenav.sistem.user',
						defaultTitle: 'Sistem Kullanıcıları',
						breadcrumb: false,
						icon: UserOutlined,
						submenu: [],
					},
					{
						key: 'mola-list',
						path: `${APP_PREFIX_PATH}/mola/list`,
						title: 'sidenav.sistem.mola',
						defaultTitle: 'Sistem Mola',
						breadcrumb: false,
						icon: CoffeeOutlined,
						submenu: [],
					},
					{
						key: 'kuyruk-list',
						path: `${APP_PREFIX_PATH}/kuyrukduzenle/list`,
						title: 'sidenav.sistem.kuyruk',
						defaultTitle: 'Sistem Kuyruk',
						breadcrumb: false,
						icon: UserOutlined,
						submenu: [],
					},
					{
						key: 'group-list',
						path: `${APP_PREFIX_PATH}/group/list`,
						title: 'sidenav.sistem.editgroup',
						defaultTitle: 'Sistem Grup',
						breadcrumb: false,
						icon: ApartmentOutlined,
						submenu: [],
					},
				],
			},
			{
				key: '100logs',
				path: `${APP_PREFIX_PATH}/logs`,
				title: 'sidenav.logs',
				defaultTitle: 'Log Kayıtları',
				breadcrumb: false,
				icon: HistoryOutlined,
				submenu: [],
			},
		],
	},
];

const navigationConfig = [...agentNavTree];

export default navigationConfig;
