import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from "antd";
import { EditOutlined, LogoutOutlined, PartitionOutlined } from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import ChangePwModal from 'components/custom-components/ChangePasswordModal';
import KuyrukSecimiModal from 'components/custom-components/KuyrukSecimiModal';
import IntlMessage from "../util-components/IntlMessage";
import socketIOClient from "socket.io-client";
import { ENDPOINT } from "configs/AppConfig";
import ls from 'localstorage-slim';
import baseService from "../../services/BaseService";

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const menuItem = [
    {
        title: setLocale(true, 'SifreDegistir'),
        icon: EditOutlined
    },
    {
        title: setLocale(true, 'KuyrukSecimi'),
        icon: PartitionOutlined
    },
    {
        title: setLocale(true, 'CikisYap'),
        icon: LogoutOutlined
    }
]

export const NavProfile = ({ signOut }) => {
    const profileImg = `${process.env.PUBLIC_URL}/img/avatars/user.png`;
    const userFirstName = ls.get("name", { decrypt: true })
    const userLastName = ls.get("surname", { decrypt: true })
    const agent = ls.get("users", { decrypt: true })
    const id = ls.get("id", { decrypt: true })

    const [kuyrukvisible, setkuyrukvisible] = useState(false)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        socket.on("logoutOtherBrowsers", userID => {
            if (+userID === +id) {
                onSignOut(true);
            }
        })
        return () => socket.close();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const showModal = () => {
        setVisible(true);

        const action = 'Şifre değiştime görüntülendi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };
    const showModal2 = () => {
        setkuyrukvisible(true);

        const action = 'Kuyruk seçimi görüntülendi.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });
    };

    const onSignOut = (reload) => {
        const action = 'Çıkış yapıldı.';

        baseService.createLog(`api/logs/create`, action).then(() => {}).catch((err) => { console.log(err) });

        if (reload) {
            window.location.reload(false);
        }
    }

    const items = [
        {
            label: <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={25} src={profileImg} className="mr-2" style={{ backgroundColor: "#FAFAFA" }} />
                    <span>{agent} - {userFirstName} {userLastName}</span>
                </div>
            </div>,
            key: '0',
        },
        {
            label:  <div className="nav-profile-body">
                <Menu>
                    {menuItem?.map((el, i) => {
                        return (
                            <Menu.Item key={i} onClick={el.icon === EditOutlined ? () => showModal()
                                : el.icon === PartitionOutlined ? e => showModal2()
                                    : el.icon === LogoutOutlined ? e => onSignOut(true)
                                        : null}>
                                <a href={el.path}>
                                    <Icon className="mr-3" type={el.icon} />
                                    <span className="font-weight-normal">{el.title}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                </Menu>

                <ChangePwModal setVisible={setVisible} visible={visible} ></ChangePwModal>

                <KuyrukSecimiModal setkuyrukvisible={setkuyrukvisible} kuyrukvisible={kuyrukvisible}></KuyrukSecimiModal>
            </div>,
            key: '1',
        },
    ];

    return (
        <Dropdown placement="bottomRight" menu={{ items }} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item id="nav-profile-avatar" key={'navProfile'}>
                    <Avatar src={profileImg} style={{ backgroundColor: "#FAFAFA" }} />
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default connect(null, { signOut })(NavProfile)
